/*-----------------------------------------------------------------------------
*
*	ACF Blocks
*
*----------------------------------------------------------------------------*/

// All block components.
.acf-block-component {

	.components-placeholder {
		margin: 0;
	}
}

.block-editor .acf-field.acf-error {
	background-color: rgba(255, 0, 0, 0.05);
}

// Block fields
.acf-block-component .acf-block-fields {
	// Ensure white background behind fields.
	background: #fff;

	// Generic body styles
	text-align: left;
	font-size: 13px;
	line-height: 1.4em;
	color: #444;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen-Sans,
		Ubuntu,
		Cantarell,
		"Helvetica Neue",
		sans-serif;

	&.acf-empty-block-fields {
		border: 1px solid #1e1e1e;
		padding: 12px;

		.components-panel & {
			border: none;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
	}

	html[dir="rtl"] & {
		text-align: right;
	}

	p {
		font-size: 13px;
		line-height: 1.5;
	}
}

// Block body.
.acf-block-body,
.acf-block-fields:has(> .acf-error-message) {

	.acf-block-fields:has(> .acf-error-message) {
		border: none !important;
	}


	.acf-error-message {
		margin-top: 0;
		border: none;

		.acf-notice-dismiss {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			// Prevent Core outline styles from impacting the close button's focus state. Without unsetting the outline, we get a black glow around the button.
			outline: unset;
		}

		.acf-icon.-cancel::before {
			margin: 0 !important;
		}

	}

	&.acf-block-has-validation-error {
		border: 2px solid #d94f4f;
	}

	.acf-error .acf-input .acf-notice {
		background: none !important;
		border: none !important;
		display: flex !important;
		align-items: center !important;
		padding-left: 0;

		p {
			margin: 0.5em 0 !important;
		}
	}


	.acf-error .acf-input .acf-notice::before {
		content: "";
		position: relative;
		top: 0;
		left: 0;
		font-size: 20px;
		background-image: url(../../../images/icons/icon-info-red.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 69%;
		height: 26px !important;
		width: 26px !important;
		box-sizing: border-box;
	}

	.acf-error .acf-label label {
		color: #d94f4f;
	}

	.acf-error .acf-input input {
		border-color: #d94f4f;
	}

	&.acf-block-has-validation-error::before {
		content: "";
		position: absolute;
		top: -2px;
		left: -32px;
		font-size: 20px;
		background-color: #d94f4f;
		background-image: url(../../../images/icons/icon-info-white.svg);
		background-repeat: no-repeat;
		background-position-x: center;
		// Offset the icon down slighly to match the notice text basline that is being impacted by the outer stroke
		background-position-y: 52%;
		background-size: 55%;
		height: 40px;
		width: 32px;
		box-sizing: border-box;
	}

	.acf-block-validation-error {
		color: #d94f4f;
		display: flex;
		align-items: center;
	}

	// Fields wrapper.
	.acf-block-fields {
		border: #adb2ad solid 1px;

		// Tab
		.acf-tab-wrap {

			.acf-tab-group {
				margin-left: 0;
				padding: 16px 20px 0;
			}
		}
	}

	// Block fields (div).
	.acf-fields > .acf-field {
		padding: 16px 20px;

		// Accordions.
		&.acf-accordion {
			border-color: #adb2ad;

			.acf-accordion-title {
				padding: 16px 20px;
			}
		}
	}

	// Ensure ACF buttons aren't changed by theme colors in the block editor.
	.acf-button,
	.acf-link a.button,
	.acf-add-checkbox {
		color: #2271b1 !important;
		border-color: #2271b1 !important;
		background: #f6f7f7 !important;
		vertical-align: top;

		&.button-primary:hover {
			color: white !important;
			background: #2271b1 !important;
		}

		&:focus {
			outline: none !important;
			background: #f6f7f7 !important;
		}

		&:hover {
			color: #0a4b78 !important;
		}
	}

	// Preview.
	.acf-block-preview {
		min-height: 10px;
	}
}

// Block panel.
.acf-block-panel {
	// Fields wrapper.
	.acf-block-fields {
		border-top: #ddd solid 1px;
		border-bottom: #ddd solid 1px;
		min-height: 1px;

		&:empty {
			border-top: none;
		}

		// Tab
		.acf-tab-wrap {
			background: transparent;
		}
	}
}

// Add compatibility for WP 5.3 and older.
// - Sidebar area is wrapped in a PanelBody element.
.components-panel__body .acf-block-panel {
	margin: 16px -16px -16px;
}
